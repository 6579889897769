import Vue from 'vue';
import Component from 'vue-class-component';
import {
  TASQ_NO_COMMS_TYPE,
  TASQ_PREDICTION_TYPE,
  TASQ_FAILURE_TYPE,
  TASQ_DOWN_TYPE,
  TASQ_OFF_TARGET_TYPE,
  TASQ_LABEL_TYPE,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_PRODUCING_TYPE,
  TASQ_PM_TYPE,
  TASQ_GAUGE_TYPE,
  TASQ_REGULATORY_TYPE,
  TASQ_SITE_CHECK_TYPE,
  TASQ_SETPOINT_TYPE,
  TASQ_WELL_TEST_TYPE,
  TASQ_SCHEDULED_JOB,
  TASQ_FAILURE_PREDICTION_TYPE,
  TASQ_OFF_TARGET_RT_TYPE,
  TASQ_WORKFLOW_TYPE,
  TASQ_STATE_CHANGE_TYPE,
  TASQ_PLUNGER_CHANGE,
  TASQ_STANDING_VALVE,
  WELL_CLICK_EVENT,
} from '@/lib/constants';

import { ucfirst,getFormattedDate as getFormattedDateHelper } from '@/utils/helpers';

@Component
export default class TasqMixin extends Vue {
  dataLoading = true;

  showWellEventDetail = false;

  showReassignTooltip: any =false;

  showSnoozeTooltip: any =false;

  snoozeTasqPopup = false;

  action: any = {
    assetType: '',
    type: '',
    title: '',
    confirmText: '',
    reason: [],
    person: '',
    comment: '',
    startDate: (new Date()).toDateString(),
    date: '',
    well: '',
    jobType: '',
    frequency: 'Today',
    delayedDays: 0,
    trigger: 'Detail',
  };

  showWaitingOnTooltip: any =false;

  created() {

  }

  getFormattedDate(date) {
    return getFormattedDateHelper(date);
  }

  wellEventDetails: any = {
	  action: {},
	  createdBy: '',
	  createdDate: '',
    comment: '',
    jobType: '',
  }

  wellEventDetailsDefault: any = {
    action: {},
    createdBy: '',
    createdDate: '',
    comment: '',
    jobType: '',
  }

  get jobTypeOptions() {
    return [
      {
        key: 'Contractor Jobs',
        value: 'Contractor Jobs',
        id: 0,
        type: 'job',
      },
      {
        key: 'Drain Pots and Drips',
        value: 'Drain Pots and Drips',
        id: 1,
        type: 'job',
      },
      {
        key: 'Drain Tank Bottoms',
        value: 'Drain Tank Bottoms',
        id: 2,
        type: 'job',
      },
      {
        key: 'Dump Checks',
        value: 'Dump Checks',
        id: 3,
        type: 'job',
      },
      {
        key: 'Equipment Inspection',
        value: 'Equipment Inspection',
        id: 4,
        type: 'job',
      },
      {
        key: 'Facility Walkdown',
        value: 'Facility Walkdown',
        id: 5,
        type: 'job',
      },
      {
        key: 'Facility Winterization',
        value: 'Facility Winterization',
        id: 6,
        type: 'job',
      },
      {
        key: 'Gauge',
        value: 'Gauge',
        id: 7,
        type: 'job',
      },
      {
        key: 'HSE',
        value: 'HSE',
        id: 8,
        type: 'job',
      },
      {
        key: 'Hot Oiling',
        value: 'Hot Oiling',
        id: 9,
        type: 'job',
      },
      {
        key: 'Install Artificial Lift',
        value: 'Install Artificial Lift',
        id: 10,
        type: 'job',
      },
      {
        key: 'Install Compressor',
        value: 'Install Compressor',
        id: 11,
        type: 'job',
      },
      {
        key: 'LOTO',
        value: 'LOTO',
        id: 12,
        type: 'job',
      },
      {
        key: 'Preventative Maintenance',
        value: 'Preventative Maintenance',
        id: 13,
        type: 'job',
      },
      {
        key: 'Regain Supply Gas',
        value: 'Regain Supply Gas',
        id: 14,
        type: 'job',
      },
      {
        key: 'Regulatory',
        value: 'Regulatory',
        id: 15,
        type: 'job',
      },
      {
        key: 'Replace Bad Battery',
        value: 'Replace Bad Battery',
        id: 16,
        type: 'job',
      },
      {
        key: 'Schedule Work',
        value: 'Schedule Work',
        id: 17,
        type: 'job',
      },
      {
        key: 'Site Check',
        value: 'Site Check',
        id: 18,
        type: 'job',
      },
      {
        key: 'Thaw Equipment',
        value: 'Thaw Equipment',
        id: 19,
        type: 'job',
      },
      {
        key: 'Unknown Well Issue',
        value: 'Unknown Well Issue',
        id: 20,
        type: 'job',
      },
      {
        key: 'Plunger Change',
        value: 'Plunger Change',
        id: 21,
        type: 'recommendation',
      },
      {
        key: 'Standing Valve',
        value: 'Standing Valve',
        id: 22,
        type: 'recommendation',
      },
    ].sort((a, b) => a.value.localeCompare(b.value));
  }


  getOverriddenTasqName(name) {
	   if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(name)) {
		   return 'Workflow';
	   }
    switch (name) {
      case TASQ_PROLONGED_ANOMALY_TYPE:
      case TASQ_STATE_CHANGE_TYPE:
        return 'Anomaly';
      case TASQ_OFF_TARGET_RT_TYPE:
      	return 'Real-Time';
      case TASQ_WELL_TEST_TYPE:
      	return 'Well test';
      case 'Off-Target RT':
        return 'REAL-TIME';
      default:
        return ucfirst(name || '');
    }
  }
}
